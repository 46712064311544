/**
 *
 * @author Ivo Danic Garrido
 * @company  Erillam Healthcare
 * @version 2.0
 */

const AppConfiguration = {
    WEB_SERVICE_HOST:process.env.NODE_ENV === "production" ? window.location.hostname : "localhost",
    WEB_SERVICE_PORT: process.env.NODE_ENV === "production" ? ":8080" : ":8080",
    WEB_SERVICE_PROTOCOL:process.env.NODE_ENV === "production" ? "http://" : "http://",
    WEB_SERVICE_UNSECURE_PATH: process.env.NODE_ENV === "production" ? "/whmws/app/" : "/whmws/app/",
    WEB_SERVICE_SECURE_PATH:  process.env.NODE_ENV === "production" ? "/whmws/app/sc/" : "/whmws/app/sc/",
  };
  
  
  export const SECURE_ENDPOINT = AppConfiguration.WEB_SERVICE_PROTOCOL.concat(
      AppConfiguration.WEB_SERVICE_HOST,
      AppConfiguration.WEB_SERVICE_PORT,
      AppConfiguration.WEB_SERVICE_SECURE_PATH
  );
  export const UNSECURE_ENDPOINT = AppConfiguration.WEB_SERVICE_PROTOCOL.concat(
      AppConfiguration.WEB_SERVICE_HOST,
      AppConfiguration.WEB_SERVICE_PORT,
      AppConfiguration.WEB_SERVICE_UNSECURE_PATH
  );
  
  /* Apartado de las encuestas  */
  
  const AppConfigurationQuiz = {
      WEB_SERVICE_HOST:  process.env.NODE_ENV === 'production' ? window.location.hostname : "localhost",
      WEB_SERVICE_PORT: process.env.NODE_ENV === 'production' ? ":8080" : ":8080",
      WEB_SERVICE_PROTOCOL: process.env.NODE_ENV === 'production'? "http://" : "http://",
      WEB_SERVICE_UNSECURE_PATH: process.env.NODE_ENV === 'production' ? "/survey/app/" :  "/survey/app/" ,
      WEB_SERVICE_SECURE_PATH: process.env.NODE_ENV === 'production' ? "/survey/app/sc/" :  "/survey/app/sc/" ,
  };
  
  export const SECURE_ENDPOINT_QUIZ = AppConfigurationQuiz.WEB_SERVICE_PROTOCOL.concat(
      AppConfigurationQuiz.WEB_SERVICE_HOST,
      AppConfigurationQuiz.WEB_SERVICE_PORT,
      AppConfigurationQuiz.WEB_SERVICE_SECURE_PATH
  );
  export const UNSECURE_ENDPOINT_QUIZ = AppConfigurationQuiz.WEB_SERVICE_PROTOCOL.concat(
      AppConfigurationQuiz.WEB_SERVICE_HOST,
      AppConfigurationQuiz.WEB_SERVICE_PORT,
      AppConfigurationQuiz.WEB_SERVICE_UNSECURE_PATH
  );
  
  
  const AppConfigurationReport = {
      WEB_SERVICE_HOST:process.env.NODE_ENV === "production" ? window.location.hostname : "localhost",
      WEB_SERVICE_PORT: process.env.NODE_ENV === "production" ? ":8080" : ":8080",
      WEB_SERVICE_PROTOCOL:process.env.NODE_ENV === "production" ? "http://" : "http://",
      WEB_SERVICE_UNSECURE_PATH: process.env.NODE_ENV === "production" ? "/wms-reports/app/" : "/wms-reports/app/",
      WEB_SERVICE_SECURE_PATH:  process.env.NODE_ENV === "production" ? "/wms-reports/app/sc/" : "/wms-reports/app/sc/",
    };
  
  export const SECURE_ENDPOINT_REPORTS = AppConfigurationReport.WEB_SERVICE_PROTOCOL.concat(
      AppConfigurationReport.WEB_SERVICE_HOST,
      AppConfigurationReport.WEB_SERVICE_PORT,
      AppConfigurationReport.WEB_SERVICE_SECURE_PATH
  );
  export const UNSECURE_ENDPOINT_REPORTS = AppConfigurationReport.WEB_SERVICE_PROTOCOL.concat(
      AppConfigurationReport.WEB_SERVICE_HOST,
      AppConfigurationReport.WEB_SERVICE_PORT,
      AppConfigurationReport.WEB_SERVICE_UNSECURE_PATH
  );
  